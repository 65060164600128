import React, { useState, useEffect, useRef } from 'react';
import useEventListener from '@use-it/event-listener'
import './calculator.css';
const Calculator = () => {
  let [result, setResult] = useState("");
  let [enteredVal, setEnteredVal] = useState("");
  let [resColor, setResColor] = useState("");
  let [isCalDataAvailabale, SetIsCalDataAvailabale] = useState(false);
  let [displayHistory, SetDisplayHistory] = useState(false);
  let [displayCalc, SetDisplayCalc] = useState(true);
  let lastClicked = localStorage.getItem("lastClickedBtn")? localStorage.getItem("lastClickedBtn") : "";
  var isCalValsData = localStorage.getItem("enteredCals") ? JSON.parse(localStorage.getItem("enteredCals")) : [];

  // key press events
  const numRegex = /^([0-9]|\.)*$/g;
  const opRegex =  /[+|\-|:|*]/g;
  const eqRegex =  /(=)/g;
  const delRegex = /(Backspace|Delete)/g;
  const acRegex = /(Escape)/g;

 
const ESCAPE_KEYS = ['27', 'Escape'];
function handler({ key }) {

  const isNumber = !!numRegex.exec(key)
  const isOperator = !!opRegex.exec(key)
  const isEqual = !!eqRegex.exec(key)
  const isDel = !!delRegex.exec(key)
  const isAc = !!acRegex.exec(key)

  // //alert(key)
  // if (ESCAPE_KEYS.includes(String(key))) {
  //   console.log('Escape key pressed!');
  // }
  if (key && isNumber || isOperator) {
    if (result.charAt(0) === '0') {
      result = result.slice(1, result.length)
    }
   
    localStorage.setItem("lastClickedBtn", key);
    setResult(result.concat(key))
  }

  if (key && isEqual) {
    calculate()
  }
  
  if (key && isDel) {
    backSpace()
  }
  if (key && isAc) {
    clear()
  }
}

useEventListener('keydown', handler);
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if(isCalValsData.length > 0) {
      SetIsCalDataAvailabale(true);
    }
  });
  const showHistory = (e) => {
    SetDisplayHistory(true);
    SetDisplayCalc(false);
    
  }

  const showCalc = (e) => {
    SetDisplayHistory(false);
    SetDisplayCalc(true);
  }

  const handleClick = (e) => {
    if(result.length >=16){
      setResult("!So Much Big Input");
      return;
    }
    if (result.charAt(0) === '0') {
      result = result.slice(1, result.length)
    }
   
    localStorage.setItem("lastClickedBtn", e.target.name);
    setResult(result.concat(e.target.name));
  }

  const clear = () => {
    setEnteredVal("");
    setResult("");
  }
  const backSpace = () => {
    setResult(result.slice(0, result.length - 1))
  }

  const calculate = () => {
    try {
      setEnteredVal(result);
      var calValsData = localStorage.getItem("enteredCals")?localStorage.getItem("enteredCals"):[];
      var calVals = localStorage.getItem("enteredCals") ? JSON.parse(calValsData): [];
      var calQues = result;
      result=eval(result).toString();
      console.log(result);
      if(result.includes('.')){
        result=+ eval(result);
        result=result.toFixed(4).toString();
        setResult(result);
      }else{
        setResult(eval(result).toString());
      }
      calVals.push(calQues+" = "+result);
      localStorage.setItem("enteredCals", JSON.stringify(calVals));
      
    } catch (err) {
      setResult("Error");
    }
  }
  return (
    <div style={{ width:"100%"}}>
    {displayCalc &&
    <div className='container'>
      <h3 style={{ fontSize: "25px", color: "chocolate", borderBottom: "2px solid whitesmoke" }}>Calculator</h3>
      <form action="">
        <div style={{ fontSize: "15px", color: "#000000", padding:'10px 0px 0px 10px'}}>
          <div style={{ float:"left", width:"40%"}}>{enteredVal}</div>
          {isCalDataAvailabale && <div style={{ float:"right", width:"40%"}}> <a href='#' onClick={showHistory}>Show History</a></div>}
        </div>
        <input type="text" value={result || 0} name="calResult"/>
      </form>

      <div className='keypad'>
        <button onClick={clear} className="clear color">Clear</button>
        <button onClick={backSpace} className="backspace color">C</button>
        <button name="/" className='color' onClick={handleClick}>/</button>
        <button name="7" className='numButtonColor' onClick={handleClick}>7</button>
        <button name="8" className='numButtonColor'onClick={handleClick}>8</button>
        <button name="9" className='numButtonColor' onClick={handleClick}>9</button>
        <button name="*" className='color' onClick={handleClick}>*</button>
        <button name="4" className='numButtonColor' onClick={handleClick}>4</button>
        <button name="5" className='numButtonColor' onClick={handleClick}>5</button>
        <button name="6" className='numButtonColor'onClick={handleClick}>6</button>
        <button name="-" className='color' onClick={handleClick}>-</button>
        <button name="1" className='numButtonColor' onClick={handleClick}>1</button>
        <button name="2" className='numButtonColor' onClick={handleClick}>2</button>
        <button name="3" className='numButtonColor' onClick={handleClick}>3</button>
        <button name="+" className='color' onClick={handleClick}>+</button>
        <button name="0" className='numButtonColor' onClick={handleClick}>0</button>
        <button name="." className='color' onClick={handleClick}>.</button>
        <button onClick={calculate} className="equal color">=</button>

      </div>
       
    </div>
}
    <>
    { isCalDataAvailabale && displayHistory && (
      <div className= "displayHistory">
        <div style={{padding:"10px", height:"40px", borderBottom: "2px solid whitesmoke"}}>
          <div style={{ float:"left", width:"70%", color: "chocolate"}}> <b>Display History</b></div>
          <div style={{ float:"right", width:"30%"}}> <a href='#' onClick={showCalc}>Back</a></div>
        </div>
        <div id="showResults" style={{padding:"10px"}}>
          {isCalValsData.map((item, index) => 
            <div key={index}>{index+1}. {item}</div>
          )}
        </div>
      </div>
    )}
  </>
  </div>
  )}

export default Calculator