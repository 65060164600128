import Calculator from './Components/Calculator';
function App() {
  return (
    <div className="App">
      <Calculator />
    </div>
  );
}

export default App;
